import React, {useEffect} from 'react';
import {Route, useLocation, withRouter} from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import ForgotPasswordRequest from "./pages/ForgotPasswordRequest";
import Password from "./pages/Password";
import PasswordReset from "./pages/PasswordReset";
import Notfound from "./pages/Notfound";
import {PrivateRoute} from "./components/PrivateRoute";
import {Role} from "./helpers/role";
import Chat from "./pages/Chat";

const AppWrapper = (props) => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    if (props.location.pathname.startsWith('/passwordreset')) {
        return <Route exact path="/passwordreset/:key" component={PasswordReset}/>
    }

    switch (props.location.pathname) {
        case "/chats":
            return <PrivateRoute roles={[Role.ROLE_USER]} path="/chats" component={Chat}/>
        case "/login":
            return <Route path="/login" component={Login}/>
        case "/register":
            return <Route path="/register" component={Register}/>
        case "/notfound":
            return <Route path="/notfound" component={Notfound}/>
        case "/forgot":
            return <Route path="/forgot" component={Forgot}/>
        case "/forgotpasswordrequest":
            return <Route path="/forgotpasswordrequest" component={ForgotPasswordRequest}/>
        default:
            return <App/>;
    }
}

export default withRouter(AppWrapper);
