import HttpService from "./HttpService";

export default class UserService extends HttpService {
    endPoint="/api/v1/gamma/users"

    getLatest() {
        return this.getAuthTokenRequest().get("/api/v1/gamma/users/latest");
    }

    createGuest(data) {
        return this.getJsonRequest()
            .post("/api/v1/gamma/users/addGuest", data);
    }
}
