import React,{Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Role} from "../helpers/role";
import {connect} from "react-redux";

class Appfooter extends Component {
    render() {
        return (
            <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
                <Link to="/home" className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <Link to="/offers" className="nav-content-bttn"><i className="feather-gift"></i></Link>}
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <Link to="/projects" className="nav-content-bttn" data-tab="chats"><i className="feather-list"></i></Link>}
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <Link to="/members" className="nav-content-bttn"><i className="feather-users"></i></Link>}
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <Link to="/settings" className="nav-content-bttn"><img src="assets/images/female-profile.png" alt="user" className="w30 shadow-xss" /></Link>}
            </div>        
        );
    }
}


function mapStateToProps(state) {
    const {account} = state.auth;

    return {
        account
    };
}

export default connect(mapStateToProps)(Appfooter);
