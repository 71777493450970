import HttpService from "./HttpService";

export default class CommentService extends HttpService {
    endPoint = "/api/v1/gamma/comments";

    getAll(targetIdentifier) {
        return this.getAuthTokenRequest().get(`/api/v1/gamma/comments/${targetIdentifier}/blocs`);
    }

    report(identifier, message) {
        return this.getAuthTokenRequest()
            .patch(`/api/v1/gamma/comments/${identifier}/reports`, JSON.stringify(message));
    }
}

