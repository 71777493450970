import React, {Component, Fragment} from "react";
import {I18n,Translate} from "react-redux-i18n";
import {Alert} from "react-bootstrap";
import AuthService from "../services/AuthService";


class ForgotPasswordRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            loading: false,
            requestSent: false
        }
        this.handleForgotPasswordRequest = this.handleForgotPasswordRequest.bind(this);
    }

    handleForgotPasswordRequest(event) {
        event.preventDefault();
        this.setState({loading: true});
        AuthService.forgotPasswordRequest(this.state.email)
            .then(() => {
                this.setState({loading: false, requestSent: true});
            })
            .catch(error => {
                this.setState({loading: false, requestSent: true});
            });
    }

    render() {
        return (
            <Fragment>
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            <a href="/"><img src='assets/images/logo.png'
                                             className="text-success display1-size me-2 ms-0"></img><span
                                className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Alumni ENSPM</span>
                            </a>
                            <button className="nav-menu me-0 ms-auto"></button>

                            <a href="/login"
                               className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w200 text-center lh-20 rounded-xl">{I18n.t('LOGIN')}</a>
                            <a href="/register"
                               className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w200 text-center lh-20 rounded-xl">{I18n.t('REGISTER')}</a>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                             style={{backgroundImage: `url('assets/images/login-background.jpg')`}}></div>

                        <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    {this.state.requestSent &&
                                    <Alert className="font-xsss fw-600" show={true} variant='info'>
                                        <Translate dangerousHTML value="FORGOT_PASSWORD_EMAIL_SENT" email={this.state.email}/>
                                   </Alert>}
                                    <form onSubmit={this.handleForgotPasswordRequest}>
                                        <div className="form-group icon-input mb-1">
                                            <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                            <input type="Email"
                                                   name="email"
                                                   required
                                                   className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                   placeholder={I18n.t('EMAIL_PLACEHOLDER')}
                                                   value={this.state.email}
                                                   onChange={(e) => this.setState({email: e.target.value})}
                                            />
                                        </div>

                                        <div className="col-sm-12 p-0 text-left">
                                            <div className="form-group mb-1">
                                                <button
                                                    type="submit"
                                                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">
                                                    {this.state.loading &&
                                                    <span className="spinner-border spinner-border-sm "
                                                          style={{marginRight: '5px'}} aria-hidden="true"></span>}
                                                    {I18n.t('CHANGE_PASSWORD')}
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </Fragment>
        );
    }
}

export default ForgotPasswordRequest;