import HttpService from "./HttpService";

export default class PostService extends HttpService {
    endPoint="/api/v1/gamma/posts"

    report(identifier, message) {
        return this.getAuthTokenRequest()
            .patch(`/api/v1/gamma/posts/${identifier}/reports`, JSON.stringify(message));
    }

    getAllPinned() {
        return this.getAuthTokenRequest().get("/api/v1/gamma/posts/allPinned");
    }

    manageLike(identifier) {
        return this.getAuthTokenRequest()
            .patch(`/api/v1/gamma/posts/${identifier}/manageLike`);
    }

    managePin(identifier) {
        return this.getAuthTokenRequest()
            .patch(`/api/v1/gamma/posts/${identifier}/managePin`);
    }
}

