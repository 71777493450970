import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {I18n} from "react-redux-i18n";
import {connect} from "react-redux";
import {Role} from "../helpers/role";

class Header extends Component {
    state = {
        isOpen: false,
        isActive: false,
        isNoti: false
    };

    toggleOpen = () => this.setState({isOpen: !this.state.isOpen});
    toggleActive = () => this.setState({isActive: !this.state.isActive});
    toggleisNoti = () => this.setState({isNoti: !this.state.isNoti});

    render() {
        const navClass = `${this.state.isOpen ? " nav-active" : ""}`;
        const buttonClass = `${this.state.isOpen ? " active" : ""}`;
        const searchClass = `${this.state.isActive ? " show" : ""}`;
        const notiClass = `${this.state.isNoti ? " show" : ""}`;

        return (
            <div className="nav-header bg-white shadow-xs border-0">
                <div className="nav-top">
                    <Link to="/home"><img src='assets/images/logo-name.png'
                                          className="text-success display2-size me-3 ms-0"></img><span
                        className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0"></span>
                    </Link>
                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                    <Link to="/chats" className="mob-menu ms-auto me-2 chat-active-btn"><i
                        className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i></Link>}
                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                    <span onClick={this.toggleActive} className="me-2 menu-search-icon mob-menu"><i
                        className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></span>}
                    <button onClick={this.toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
                </div>

                <form action="#" className="float-left header-search ms-3">
                    <div className="form-group mb-0 icon-input">
                        <i className="feather-search font-sm text-grey-400"></i>
                        <input type="text" placeholder={I18n.t('SEARCH_A_POST')}
                               className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"/>
                    </div>
                </form>
                <NavLink activeClassName="active" to="/home"
                         className="p-2 text-center ms-3 menu-icon center-menu-icon"><i
                    className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <NavLink activeClassName="active" to="/offers"
                         className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
                    className="feather-gift font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>}
                    <NavLink activeClassName="active" to="/projects"
                         className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
                    className="feather-list font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                    <NavLink activeClassName="active" to="/members"
                         className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
                    className="feather-users font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>}
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <span className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`} id="dropdownMenu3"
                      data-bs-toggle="dropdown" aria-expanded="false" onClick={this.toggleisNoti}><span
                    className="dot-count bg-warning"></span><i className="feather-bell font-xl text-current"></i></span>}
                <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`}
                     aria-labelledby="dropdownMenu3">
                    <h4 className="fw-700 font-xss mb-4">Notifications</h4>
                    <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                        <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                        <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Hendrix Stamp <span
                            className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min</span></h5>
                        <h6 className="text-grey-500 fw-500 font-xssss lh-4">There are many variations of pass..</h6>
                    </div>
                    <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                        <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                        <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span
                            className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
                        <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                    </div>

                    <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                        <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                        <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span
                            className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
                        <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                    </div>
                    <div className="card bg-transparent-card w-100 border-0 ps-5">
                        <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0"/>
                        <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span
                            className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
                        <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                    </div>
                    <div className="card-body d-block w-100 pt-0">
                        <a href="/notifications"
                           className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl"><i
                            className="feather-external-link font-xss me-2"></i> Tout afficher </a>
                    </div>
                </div>
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                <Link to="/chats" className="p-2 text-center ms-3 menu-icon chat-active-btn"><i
                    className="feather-message-square font-xl text-current"></i></Link>}
                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                    <Link to="/settings" className="p-0 ms-3 menu-icon"><img src="assets/images/user.png" alt="user"
                                                                         className="w40 mt--1"/></Link>}

                <nav className={`navigation scroll-bar ${navClass}`}>
                    <div className="container ps-0 pe-0">
                        <div className="nav-content">
                            <div
                                className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                                <div className="nav-caption fw-600 font-xssss text-grey-500">Pages</div>
                                <ul className="mb-1 top-content">
                                    <li className="logo d-none d-xl-block d-lg-block"></li>
                                    <li><Link to="/about" className="nav-content-bttn open-font"><i
                                        className="feather-award btn-round-md bg-green-gradiant me-3"></i><span>Association</span></Link>
                                    </li>
                                    <li><Link to="/home" className="nav-content-bttn open-font"><i
                                        className="feather-home btn-round-md bg-blue-gradiant me-3"></i><span>{I18n.t('POSTS')}</span></Link>
                                    </li>
                                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <li><Link to="/offers" className="nav-content-bttn open-font"><i
                                        className="feather-gift btn-round-md bg-red-gradiant me-3"></i><span>{I18n.t('OFFERS')}</span></Link>
                                    </li>}
                                     <li><Link to="/projects" className="nav-content-bttn open-font"><i
                                        className="feather-list btn-round-md bg-gold-gradiant me-3"></i><span>{I18n.t('PROJECTS')}</span></Link>
                                    </li>
                                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <li><Link to="/members" className="nav-content-bttn open-font"><i
                                        className="feather-users btn-round-md bg-mini-gradiant me-3"></i><span>{I18n.t('MEMBERS')}</span></Link>
                                    </li>}
                                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <li><Link to="/profile" className="nav-content-bttn open-font"><i
                                        className="feather-user btn-round-md bg-primary-gradiant me-3"></i><span>{I18n.t('PROFILE')}</span></Link>
                                    </li>}
                                </ul>
                            </div>

                            {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                            <div
                                className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                                <div
                                    className="nav-caption fw-600 font-xssss text-grey-500">{I18n.t('MORE_PAGES')}</div>
                                <ul className="mb-3">
                                    <li><Link to="/mailbox" className="nav-content-bttn open-font"><i
                                        className="font-xl text-current feather-inbox me-3"></i><span>{I18n.t('EMAILS')}</span><span
                                        className="circle-count bg-warning mt-1">584</span></Link></li>
                                    <li><Link to="/chats" className="nav-content-bttn open-font"><i
                                        className="font-xl text-current feather-message-square me-3"></i><span>Chat</span><span
                                        className="circle-count bg-warning mt-0">23</span></Link></li>
                                    <li><Link to="/notifications" className="nav-content-bttn open-font"><i
                                        className="font-xl text-current feather-bell me-3"></i><span>Notifications</span><span
                                        className="circle-count bg-warning mt-0">23</span></Link></li>
                                    <li><Link to="/events" className="nav-content-bttn open-font"><i
                                        className="font-xl text-current feather-map-pin me-3"></i><span>{I18n.t('EVENTS')}</span></Link>
                                    </li>
                                </ul>
                            </div>}
                                <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                                <div className="nav-caption fw-600 font-xssss text-grey-500">{I18n.t('ACCOUNT')}</div>
                                <ul className="mb-1">
                                    <li className="logo d-none d-xl-block d-lg-block"></li>
                                    <li><Link to="/settings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                                        className="font-sm feather-settings me-3 text-grey-500"></i><span>{I18n.t('SETTINGS')}</span></Link>
                                    </li>
                                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <li><Link to="/analytics" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                                        className="font-sm feather-pie-chart me-3 text-grey-500"></i><span>{I18n.t('ANALYTICS')}</span></Link>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className={`app-header-search ${searchClass}`}>
                    <form className="search-form">
                        <div className="form-group searchbox mb-0 border-0 p-1">
                            <input type="text" className="form-control border-0" placeholder="Search..."/>
                            <i className="input-icon">
                                <ion-icon name="search-outline" role="img" className="md hydrated"
                                          aria-label="search outline"></ion-icon>
                            </i>
                            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                                <i className="ti-close font-xs" onClick={this.toggleActive}></i>
                            </span>
                        </div>
                    </form>
                </div>

            </div>
        );
    }
}


function mapStateToProps(state) {
    const {account} = state.auth;

    return {
        account
    };
}

export default connect(mapStateToProps)(Header);

