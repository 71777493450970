import axios from "axios";
import store from "../store";
import {REFRESH_TOKEN_SUCCESS} from "../actions/types";
import {logout, profile} from "../actions/auth";
import {Constant} from '../helpers/const';
import {getBrowser} from "../helpers/utils";

export default class HttpService {
    endPoint = ""
    defaultOptions = {
        baseURL: Constant.API_BASE,
        timeout: 200000, //200 secondes
        headers: {
            'Content-Type': 'application/json',
            'currentSession': JSON.stringify(getBrowser())
        },
    };

    getJsonRequest() {
        // Create instance
        let instance = axios.create(this.defaultOptions);
        return instance;
    }

    getAuthTokenRequest() {
        // Create instance
        let that = this;
        let instance = axios.create(this.defaultOptions);

        instance.interceptors.request.use(function (config) {
            const user = JSON.parse(localStorage.getItem(Constant.ACCOUNT));
            config.headers.Authorization = (user && user.accessToken) ? `Bearer ${user.accessToken}` : '';
            return config;
        });

        // Set the AUTH token for any request
        // for multiple requests
        let isRefreshing = false;
        let failedQueue = [];

        const processQueue = (error, token = null) => {
            failedQueue.forEach(prom => {
                if (error) {
                    prom.reject(error);
                } else {
                    prom.resolve(token);
                }
            })

            failedQueue = [];
        }

        instance.interceptors.response.use(function (response) {
            return response;
        }, function (error) {

            const originalRequest = error.config;

            console.log("error value ...", error)


            if (error.response.status === 401 && error.response.data.code === 'TOKEN_EXPIRED' && !originalRequest._retry) {
                console.log('je suis la ...')

                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({resolve, reject})

                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    }).catch(err => {
                        return Promise.reject(err);
                    })
                }

                originalRequest._retry = true;
                isRefreshing = true;


                const user = JSON.parse(localStorage.getItem(Constant.ACCOUNT));
                const refreshToken = user.refreshToken;
                return new Promise(function (resolve, reject) {
                    axios.create(that.defaultOptions).post("/api/v1/gamma/auth/refresh", {refreshToken})
                        .then(({data}) => {
                            localStorage.setItem(Constant.ACCOUNT, JSON.stringify(data));
                            store.dispatch({
                                type: REFRESH_TOKEN_SUCCESS,
                                payload: {account: data},
                            });
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
                            originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
                            processQueue(null, data.accessToken);
                            resolve(axios(originalRequest));
                        })
                        .then(() => {
                            return store.dispatch(profile());
                        })
                        .catch((err) => {
                            console.log("error when refreshing  ...", err)
                            store.dispatch(logout());

                            //processQueue(err, null);
                            //reject(err);
                        })
                        .finally(() => {
                            isRefreshing = false
                        })
                })
            }
            return Promise.reject(error);
        });
        return instance;
    }

    getPage(start, pageSize, sortField, order, searchFilter) {
        let params = {start: start, size: pageSize, sort: sortField, order: order}
        if (!!searchFilter) {
            searchFilter.forEach(function (obj) {
                params[obj.field] = obj.value
            })
        }
        return this.getAuthTokenRequest()
            .get(this.endPoint, {params: params});
    }

    getAll() {
        return this.getAuthTokenRequest().get(this.endPoint + "/all");
    }

    add(data) {
        return this.getAuthTokenRequest()
            .post(this.endPoint, data);
    }

    edit(id, data) {
        return this.getAuthTokenRequest()
            .patch(this.endPoint + '/' + id, data);
    }

    delete(id) {
        return this.getAuthTokenRequest()
            .delete(this.endPoint + '/' + id);
    }

    deleteMany(dataIds) {
        return this.getAuthTokenRequest()
            .patch(this.endPoint + "/deleteRecords", dataIds);
    }

}

