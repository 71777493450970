import React, {Component} from 'react';
import UserService from "../services/UserService";
import {getProfileUrl} from "../helpers/utils";
import {I18n} from "react-redux-i18n";
import Load from "./Load";

class LastRegistered extends Component {

    constructor(props) {
        super(props);
        this.state = {
            elements: [],
            loading: true
        }
        this.elementService = new UserService();
    }

    componentDidMount() {
        this.elementService.getLatest()
            .then(response => {
                //console.log('latest elements ...', response)
                this.setState({
                    elements: response.data.content.list? response.data.content.list:[],
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
            });
    }


    render() {
        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex align-items-center p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">{I18n.t('LATEST_REGISTERED')}</h4>
                    <a href="/members" className="fw-600 ms-auto font-xssss text-primary">{I18n.t('SEE_ALL')}</a>
                </div>
                {this.state.loading?  <Load/>:
                this.state.elements.map((value, index) => (
                    <div key={index}
                         className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3">
                        <figure className="avatar me-2 mb-0"><img src={getProfileUrl(value)} alt="avatar"
                                                                  className="shadow-sm rounded-circle w45"/></figure>
                        <h4 className="fw-700 text-grey-900 font-xssss mt-2">{value.account && value.account.contact.firstName} {value.account && value.account.contact.lastName}
                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value.department && value.department.acronym}</span>
                        </h4>
                        {/*<a href="/profile" className="btn-round-sm bg-white ms-auto mt-2"><span
                            className="feather-chevron-right font-xss text-grey-900"></span></a>*/}
                    </div>
                ))}
            </div>
        );
    }
}

export default LastRegistered;
