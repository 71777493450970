import React, {Component} from 'react';
import TimeAgo from "react-timeago/lib";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import englishStrings from "react-timeago/lib/language-strings/en";
import {connect} from "react-redux";

class AlumniTimeAgo extends Component {

    render() {
        let formatter
        if (this.props.locale === 'fr-FR') {
            formatter = buildFormatter(frenchStrings)
        } else {
            formatter = buildFormatter(englishStrings)
        }

        return (
            <TimeAgo
                date={this.props.date}
                formatter={formatter}/>
        );
    }
}


function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(AlumniTimeAgo);
