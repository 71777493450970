import HttpService from "./HttpService";

export default class ProjectService extends HttpService {
    endPoint="/api/v1/gamma/projects"

    manageLike(identifier) {
        return this.getAuthTokenRequest()
            .patch(`/api/v1/gamma/projects/${identifier}/manageLike`);
    }

    managePin(identifier) {
        return this.getAuthTokenRequest()
            .patch(`/api/v1/gamma/projects/${identifier}/managePin`);
    }

    contribute(data) {
        return this.getAuthTokenRequest()
            .post("/api/v1/gamma/projects/contributions", data);
    }
}

