// React Required
import React from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/bootstrap.css'

// Common Layout
import {Provider} from "react-redux";
import store from "./store";

import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import AppWrapper from "./AppWrapper";


ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppWrapper/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
