import React, {Component} from 'react';
import ReadMoreReact from 'read-more-react';
import {getProfileUrl} from "../helpers/utils";
import Load from "./Load";
import TimeAgo  from "react-timeago/lib";
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

class Postview extends Component {
    state = {
        isOpen: false
    };

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    render() {
        let formatter = buildFormatter(frenchStrings)
        const {data} = this.props;

        const menuClass = `${this.state.isOpen ? " show" : ""}`;
        const emojiClass = `${this.state.isActive ? " active" : ""}`;


        if (data === undefined)
            return <Load/>

        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                <div className="card-body p-0 d-flex">
                    <figure className="avatar me-3"><img src={getProfileUrl(data.account)} alt="avatar"
                                                         className="shadow-sm rounded-circle w45"/></figure>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {data.account.firstName} {data.user.lastName} <span
                        className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                        <TimeAgo
                        date={data.createdOn}
                        formatter={formatter}/></span></h4>
                    <div className={`ms-auto pointer ${menuClass}`} id="dropdownMenu4" data-bs-toggle="dropdown" aria-expanded="false" onClick={this.toggleOpen}><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div>
                    <div  className={`dropdown-menu p-4 top-70 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby="dropdownMenu4">
                        <div className="card-body p-0 d-flex">
                            <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Épingler <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Add this to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Modifier <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Supprimer <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">Signaler <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                    </div>

                </div>
                {data.media && data.media.contentType.startsWith('video') ?
                    <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                        <a href={data.media.url} className="video-btn">
                            <video autoPlay loop className="float-right w-100">
                                <source src={data.media.url} type={data.media.contentType}/>
                            </video>
                        </a>
                    </div>
                    : ''}
                <div className="card-body p-0">
                    <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                        <ReadMoreReact
                            text={data.description}
                            min={250}
                            ideal={300}
                            max={350}
                            readMoreText="En savoir plus"/>
                    </p>
                </div>
                {data.media && data.media.contentType.startsWith('image') ?
                    <div className="card-body d-block p-0 mb-3">
                        <div className="row ps-2 pe-2">
                            <div className="col-sm-12 p-1"><img src={data.media.url}
                                                                className="rounded-3 w-100" alt="post"/></div>
                        </div>
                    </div>
                    : ''}
                <div className="card-body d-flex p-0">
                    <div
                        className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                        onClick={this.toggleActive}><i
                        className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> <i
                        className="feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss"></i>2.8K Like
                    </div>
                    <div className={`emoji-wrap pointer ${emojiClass}`}>
                        <ul className="emojis list-inline mb-0">
                            <li className="emoji list-inline-item"><i className="em em---1"></i></li>
                            <li className="emoji list-inline-item"><i className="em em-angry"></i></li>
                            <li className="emoji list-inline-item"><i className="em em-anguished"></i></li>
                            <li className="emoji list-inline-item"><i className="em em-astonished"></i></li>
                            <li className="emoji list-inline-item"><i className="em em-blush"></i></li>
                            <li className="emoji list-inline-item"><i className="em em-clap"></i></li>
                            <li className="emoji list-inline-item"><i className="em em-cry"></i></li>
                            <li className="emoji list-inline-item"><i className="em em-full_moon_with_face"></i></li>
                        </ul>
                    </div>
                    <a href="/defaultvideo"
                       className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i
                        className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span
                        className="d-none-xss">22 Comment</span></a>

                </div>

            </div>
        );
    }
}

export default Postview;