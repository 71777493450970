import React, {Component} from 'react';
import ReadMoreReact from "read-more-react";
import {I18n} from "react-redux-i18n";

class AlumniReadMore extends Component {

    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
            update: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevState.text !== this.props.text) {
            this.setState({
                text: this.props.text,
                update: true
            });
        }
    }

    render() {
        let result = <ReadMoreReact
            text={this.props.text}
            min={250}
            ideal={300}
            max={350}
            readMoreText={I18n.t('READ_MORE_TEXT')}/>

        if (this.state.update === true) {
            result = ""
            this.setState({
                update: false
            });
        }

        return (
            <div style={{whiteSpace: 'pre-line',lineHeight: '20px'}}>{result}</div>
        );
    }
}

export default AlumniReadMore;
